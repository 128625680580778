var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "form-group col-md-6"
  }, [_c('label', {
    staticStyle: {
      "text-transform": "capitalize"
    },
    attrs: {
      "for": ""
    }
  }, [_vm._v(_vm._s(_vm.$t("phase")))]), _c('p', {
    staticClass: "form-control-static",
    staticStyle: {
      "text-transform": "uppercase"
    }
  }, [_vm._v(" " + _vm._s(_vm.branch.phase ? _vm.branch.phase : "-") + " ")])]), _c('div', {
    staticClass: "form-group col-md-6"
  }, [_c('label', {
    staticStyle: {
      "text-transform": "capitalize"
    },
    attrs: {
      "for": ""
    }
  }, [_vm._v(_vm._s(_vm.$t("org.branch-name")))]), _c('p', {
    staticClass: "form-control-static",
    staticStyle: {
      "text-transform": "uppercase"
    }
  }, [_vm._v(" " + _vm._s(_vm.branch.branch_name) + " ")])]), _c('div', {
    staticClass: "form-group col-md-6"
  }, [_c('label', {
    staticStyle: {
      "text-transform": "capitalize"
    },
    attrs: {
      "for": ""
    }
  }, [_vm._v(_vm._s(_vm.$t("org.branch-code")))]), _c('p', {
    staticClass: "form-control-static",
    staticStyle: {
      "text-transform": "uppercase"
    }
  }, [_vm._v(" " + _vm._s(_vm.branch.code ? _vm.branch.code : "-") + " ")])]), _c('div', {
    staticClass: "form-group col-md-6"
  }, [_c('label', {
    staticStyle: {
      "text-transform": "capitalize"
    },
    attrs: {
      "for": ""
    }
  }, [_vm._v(_vm._s(_vm.$t("address")))]), _c('p', {
    staticClass: "form-control-static",
    staticStyle: {
      "text-transform": "uppercase"
    }
  }, [_vm._v(" " + _vm._s(_vm.fullAddress) + " ")])]), _c('div', {
    staticClass: "form-group col-md-6"
  }, [_c('label', {
    staticStyle: {
      "text-transform": "capitalize"
    },
    attrs: {
      "for": ""
    }
  }, [_vm._v(_vm._s(_vm.$t("office-no")))]), _c('p', {
    staticClass: "form-control-static"
  }, [_vm._v(" " + _vm._s(_vm.branch.phone_number ? _vm.branch.phone_number : "-") + " ")])]), _vm.organization_type != 'Awaris' ? _c('div', {
    staticClass: "form-group col-md-6"
  }, [_c('label', {
    staticStyle: {
      "text-transform": "capitalize"
    },
    attrs: {
      "for": ""
    }
  }, [_vm._v(_vm._s(_vm.$t("website")))]), _c('p', {
    staticClass: "form-control-static"
  }, [_vm._v(" " + _vm._s(_vm.branch.website ? _vm.branch.website : "-") + " ")])]) : _vm._e(), _vm.organization_type != 'Awaris' ? _c('div', {
    staticClass: "form-group col-md-6"
  }, [_c('label', {
    attrs: {
      "for": ""
    }
  }, [_vm._v(_vm._s(_vm.$t("email")))]), _c('p', {
    staticClass: "form-control-static"
  }, [_vm._v(" " + _vm._s(_vm.branch.email ? _vm.branch.email : "-") + " ")])]) : _vm._e(), _vm.organization_type != 'Awaris' ? _c('div', {
    staticClass: "form-group col-md-6"
  }, [_c('label', {
    staticStyle: {
      "text-transform": "capitalize"
    },
    attrs: {
      "for": ""
    }
  }, [_vm._v(_vm._s(_vm.$t("bank-account")))]), _c('p', {
    staticClass: "form-control-static"
  }, [_vm._v(" " + _vm._s(_vm.branch.bank_account_number ? _vm.branch.bank_account_number : "-") + " ")])]) : _vm._e(), _vm.organization_type != 'Awaris' ? _c('div', {
    staticClass: "form-group col-md-6"
  }, [_c('label', {
    staticStyle: {
      "text-transform": "capitalize"
    },
    attrs: {
      "for": ""
    }
  }, [_vm._v(_vm._s(_vm.$t("acc-holder")))]), _c('p', {
    staticClass: "form-control-static",
    staticStyle: {
      "text-transform": "uppercase"
    }
  }, [_vm._v(" " + _vm._s(_vm.branch.bank_account_holder ? _vm.branch.bank_account_holder : "-") + " ")])]) : _vm._e(), _vm.organization_type != 'Awaris' ? _c('div', {
    staticClass: "form-group col-md-6"
  }, [_c('label', {
    staticStyle: {
      "text-transform": "capitalize"
    },
    attrs: {
      "for": ""
    }
  }, [_vm._v(_vm._s(_vm.$t("bankname")))]), _c('p', {
    staticClass: "form-control-static",
    staticStyle: {
      "text-transform": "uppercase"
    }
  }, [_vm._v(" " + _vm._s(_vm.branch.bank_name ? _vm.branch.bank_name : "-") + " ")])]) : _vm._e(), _c('div', {
    staticClass: "form-group col-md-6"
  }, [_c('label', {
    staticStyle: {
      "text-transform": "capitalize"
    },
    attrs: {
      "for": ""
    }
  }, [_vm._v(_vm._s(_vm.$t("status")))]), _c('p', {
    staticClass: "form-control-static"
  }, [_vm.branch.status == 1 ? _c('span', {
    staticClass: "badge badge-success"
  }, [_vm._v(_vm._s(_vm.$t("active")))]) : _vm._e(), _vm.branch.status != 1 ? _c('span', {
    staticClass: "badge badge-warning"
  }, [_vm._v(_vm._s(_vm.$t("inactive")))]) : _vm._e()])])]), _c('hr'), _c('div', {
    staticClass: "d-flex justify-content-between"
  }, [_c('button', {
    staticClass: "btn btn-primary",
    attrs: {
      "type": "button"
    },
    on: {
      "click": _vm.back
    }
  }, [_c('i', {
    staticClass: "mdi mdi-chevron-left"
  }), _vm._v(_vm._s(_vm.$t("back")) + " ")]), ['Staff', 'Super Admin', 'IT'].includes(_vm.userRole) ? _c('router-link', {
    staticClass: "btn btn-primary",
    attrs: {
      "to": {
        name: 'organization.branch.edit',
        params: {
          organization_id: _vm.organizationId,
          branch_id: _vm.branchId
        }
      }
    }
  }, [_vm._v(_vm._s(_vm.$t("update")))]) : _vm._e()], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }